import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import "./style.css";

function App() {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  const handleAsk = () => {
    setLoading(true);

    let data = JSON.stringify({
      user_input: question,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://answers.dev.beingtheparent.com/query",
      headers: {
        "Content-Type": "application/json",
        accesstoken:
          "eyJhbGciOiJIUzI1NiJ9.OTI2NQ.TWKGUoW7yuhBFNYVqQJhh6ed7pedj4SigQoIz9W33rs",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data.response));
        setAnswer(response.data.response);

        // Update chat history and reset input fields
        const newChatHistory = [...chatHistory];
        newChatHistory.push({ role: "user", content: question });
        newChatHistory.push({ role: "bot", content: response.data.response });
        setChatHistory(newChatHistory);
        setQuestion("");
        setAnswer("");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent page reload
      handleAsk(); // Call your function to handle the question
    }
  };

  return (
    <div className="App">
      <div className="chat-container">
        <div className="chat-box">
          <div className="message received">
            Hello,I am btpGpt how can I help you?
          </div>
          {chatHistory.map((message, index) => (
            <div className={`message ${message.role}`} key={index}>
              {message.content}
            </div>
          ))}
        </div>
        <Form className="input-form">
          <Form.Control
            type="text"
            autoFocus
            name="question"
            value={question}
            className="input"
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Type your question..."
            onKeyPress={handleKeyPress}
          />
          <Button variant="secondary" onClick={handleAsk}>
            Ask
          </Button>
        </Form>
        {loading && <Spinner animation="border" role="status" />}
      </div>
    </div>
  );
}

export default App;
